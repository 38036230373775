import React, { useState } from "react";
import { Container, Typography, Box, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    budget: "",
    consent: false,
  });

  const [errors, setErrors] = useState({ name: "", email: "", consent: ""});
  const [isLoading, setIsLoading] = useState(false);

  const validateName = (name: string) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!name) return "Il nome è obbligatorio.";
    if (!nameRegex.test(name))
      return "Il nome può contenere solo lettere e spazi.";
    return "";
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return "L'email è obbligatoria.";
    if (!emailRegex.test(email)) return "Inserisci un'email valida.";
    return "";
  };

  const validateConsent = (consent: boolean) => {
    if (!consent) return "Devi accettare il trattamente dei dati personali.";
    return "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const nameError = validateName(formData.name);
    const emailError = validateEmail(formData.email);
    const consentError = validateConsent(formData.consent);

    if (nameError || emailError || consentError) {
      setErrors({ name: nameError, email: emailError, consent: consentError });
      return;
    }

    setIsLoading(true); // Inizio caricamento
    emailjs
      .send(
        "service_94bmx1p", // Sostituisci con il tuo Service ID
        "template_9xei78q", // Sostituisci con il tuo Template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
          budget: formData.budget || "Non specificato",
        },
        "g5CVqx1aOgjzN06m7" // Sostituisci con la tua Public Key
      )
      .then(
        () => {
          alert("Grazie per averci contattato! Ti risponderemo presto.");
          setFormData({ name: "", email: "", message: "", budget: "", consent: false });
          setErrors({ name: "", email: "", consent: "" });
          setIsLoading(false); // Fine caricamento
        },
        (error) => {
          console.error("Errore:", error);
          alert(
            "Si è verificato un errore durante l'invio. Riprova più tardi."
          );
          setIsLoading(false); // Fine caricamento
        }
      );
  };

  return (
    <>
      {/* SEO: Titolo e meta description */}
      <head>
        <title>Contattaci - Siamo a tua disposizione</title>
        <meta
          name="description"
          content="Contattaci per discutere di un progetto o per qualsiasi domanda. Compila il modulo e ti risponderemo al più presto."
        />
      </head>

      <Container maxWidth="md" sx={{ py: 4, marginBottom:{xl:"16rem"} }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center", fontSize:{xl:"6rem"}, marginTop:{xxl:"2rem"} }}>
          Contattaci
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign: "center", mb: 3, fontSize: {sm:"1.2rem", xl:"2rem"}, color: "#555" }}
        >
          Hai domande o vuoi discutere di un progetto? Compila il modulo qui
          sotto!
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Nome"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
            />
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
            />
            <TextField
              label="Note (Parlaci della tua idea)"
              variant="outlined"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
            />
            <TextField
              label="Budget (opzionale)"
              variant="outlined"
              name="budget"
              value={formData.budget}
              onChange={handleChange}
              fullWidth
            />
            <FormControlLabel
            control={
              <Checkbox
              name="consent"
              checked={formData.consent}
              onChange={handleChange}
              />
            }
            label="Accetto il trattamento dei miei dati personali"
            />
            {errors.consent && (
              <Typography variant="caption" color="error">
                {errors.consent}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ alignSelf: "center", px: 5 }}
              disabled={isLoading}
            >
              {isLoading ? "Invio in corso..." : "Invia"}
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default Contact;
