import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    //Controlla il consenso salvato
    const consent = localStorage.getItem("cookie-consent");
    if (!consent) {
      setShowConsent(true); //Mostra il banner se non c'è il consenso
    } else if (consent === "accepted") {
      // Se l'utente ha accettato, carica Google Analitics
      loadGoogleAnalytics();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookie-consent", "accepted");
    setShowConsent(false);
    loadGoogleAnalytics(); // Carica Analitics immediatamente dopo l'accettazione
  };

  const loadGoogleAnalytics = () => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-HS6FJVLYSX";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any[]) {
        window.dataLayer.push(args);
      }
      gtag("js", new Date());
      gtag("config", "G-HS6FJVLYSX"); // Sostituisci con il tuo ID di tracciamento
    };
  };

  const handleDecline = () => {
    localStorage.setItem("cookie-consent", "declined");
    setShowConsent(false);
  };

  if (!showConsent) return null; // Non mostrare il banner se il consenso è già stato dato o rifiutato

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#f1f1f1",
        boxShadow: "0px -2px 4px rgba(0,0,0,0.1)",
        padding: "10px 20px",
        zIndex: 1000,
      }}
    >
      <Typography variant="body2" sx={{ display: "inline", mr: 2 }}>
        Questo sito utilizza i cookie per migliorare l'esperienza utente. Per
        saperne di più, consulta la nostra{" "}
        <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
          Privacy Policy
        </a>
        .
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAccept}
        sx={{ mr: 1 }}
      >
        Accetta
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleDecline}>
        Rifiuta
      </Button>
    </Box>
  );
};

export default CookieConsent;
