import React from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h3" gutterBottom>
        Informativa sulla Privacy
      </Typography>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1" gutterBottom>
          La tua privacy è importante per noi. Questa informativa sulla privacy
          spiega come raccogliamo, utilizziamo e proteggiamo i tuoi dati
          personali in conformità con il Regolamento Generale sulla Protezione
          dei Dati (GDPR).
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Dati raccolti
        </Typography>
        <Typography variant="body1" gutterBottom>
          Raccogliamo i seguenti dati personali:
        </Typography>
        <ul>
          <li>Nome e cognome</li>
          <li>Indirizzo email</li>
          <li>Messaggi inviati tramite il modulo di contatto</li>
        </ul>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Finalità del trattamento
        </Typography>
        <Typography variant="body1" gutterBottom>
          I tuoi dati sono utilizzati esclusivamente per rispondere alle tue
          richieste e fornire i servizi richiesti.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Condivisione dei dati
        </Typography>
        <Typography variant="body1" gutterBottom>
          I tuoi dati non saranno condivisi con terze parti senza il tuo
          consenso, a meno che non sia richiesto dalla legge.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Diritti dell'utente
        </Typography>
        <Typography variant="body1" gutterBottom>
          Hai il diritto di accedere, rettificare, cancellare o limitare il
          trattamento dei tuoi dati personali. Puoi contattarci per esercitare
          questi diritti.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
          Contatti
        </Typography>
        <Typography variant="body1" gutterBottom>
          Per qualsiasi domanda relativa alla privacy, contattaci a{" "}
          <a href="mailto:kavidubusiness@gmail.com">kavidubusiness@gmail.com</a>.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
