import React from "react";
import { Container, Grid, Typography, Link, IconButton } from "@mui/material";
import { Facebook, Twitter, LinkedIn, GitHub } from "@mui/icons-material";

export function Footer() {
  return (
    <footer
      style={{
        backgroundColor: "#ced6e0",
        color: "#fff",
        padding: "40px 0",
        //marginTop: "40px",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          {/* Sezione 1: Informazioni generali */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="#000000" sx={{ fontWeight: 600 }}>
              Progetti Web
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginTop: "10px" }}
            >
              Tutti i diritti riservati. 2024
            </Typography>
          </Grid>

          {/* Sezione 2: Link alle pagine principali */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="#000000" sx={{ fontWeight: 600 }}>
              Pagine principali
            </Typography>
            <Grid
              container
              spacing={1}
              direction="column"
              sx={{ marginTop: "10px" }}
            >
              <Grid item>
                <Link href="/" color="textSecondary" variant="body2">
                  Home
                </Link>
              </Grid>
              <Grid item>
                <Link href="/ChiSiamo" color="textSecondary" variant="body2">
                  Chi Siamo
                </Link>
              </Grid>
              <Grid item>
                <Link href="/Contattaci" color="textSecondary" variant="body2">
                  Contattaci
                </Link>
              </Grid>
              <Grid item>
                <Link href="/privacy-policy" color="textSecondary" variant="body2">
                  Pricacy e Policy
                </Link>
              </Grid>
            </Grid>
          </Grid>

          {/* Sezione 3: Link social media con loghi */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="#000000" sx={{ fontWeight: 600 }}>
              Seguici
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
              <Grid item>
                <IconButton
                  href="https://www.facebook.com/profile.php?id=61556771721429"
                  color="info"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href="https://twitter.com"
                  color="info"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href="https://www.linkedin.com/in/kavidu-warnakulasuriyadeveloper/"
                  color="info"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href="https://github.com/kavidiuk"
                  color="primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHub fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Sezione Copyright */}
        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center">
              © {new Date().getFullYear()} Progetti Web. Tutti i diritti
              riservati.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
