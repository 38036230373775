import React from "react";
import gif from "../images/GifPersonalizzata.gif";

export default function Gif() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src={gif}
        alt="Innovative digital solution"
        style={{
          maxWidth: "80%",
          height: "auto",
        }}
      />
    </div>
  );
}
