import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemButton, ListItemText, Container } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/images/logo2.png";
// Lista delle pagine
const pages = [
  { name: "HomePage", link: "/" },
  { name: "Chi Siamo", link: "/ChiSiamo" },
  { name: "Contattaci", link: "/Contattaci" },
];

export function Navbar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [visibile, setVisibile] = React.useState(true);  // Navbar visibile all'inizio
  const prevScrollY = React.useRef(0);  // Memorizza la posizione precedente dello scroll

  const handleScroll = React.useCallback(() => {
    const currentScrollY = window.scrollY;
    // Se l'utente sta scorrendo verso il basso e non è in cima, nascondi la navbar
    if (currentScrollY > prevScrollY.current && currentScrollY > 50) {
      setVisibile(false);  // Nasconde la navbar
    } else {
      setVisibile(true);   // Mostra la navbar
    }
    prevScrollY.current = currentScrollY;
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const navigate = useNavigate();

  const handleNavigation = (link: string) => {
    navigate(link);
    setDrawerOpen(false);
  };

  const handleHomeNavigation = () => {
    navigate("/")
  }

  const toggleDrawer = (open: boolean) => setDrawerOpen(open);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#fff",
        borderBottom: "1px solid #e0e0e0",
        boxShadow: "none",
        transition: "transform 0.3s ease",
        transform: visibile ? "translateY(0)" : "translateY(-100%)", // Nasconde la navbar quando scorre verso il basso
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo */}
          <Box sx={{ width: "4rem", cursor: "pointer", display:{xs: "none", md: "block"} }} onClick={handleHomeNavigation}>
        <img src={logo} alt="Logo" style={{ width: "100%", height: "auto" }} />

        </Box>


          {/* Mobile Menu Icon */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="Apri menu"
              onClick={() => toggleDrawer(true)}
              sx={{ color: "#000" }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Drawer (Mobile Menu) */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
            sx={{
              "& .MuiDrawer-paper": {
                width: "60%",
                height: "100vh",
                backgroundColor: "#fff",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: 2,
                color: "#0070f3",
              }}
            >
              Menu
            </Typography>
            <List>
              {pages.map((page, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton component="a" href={page.link} onClick={() => handleNavigation(page.link)}>
                    <ListItemText primary={page.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>

          {/* Desktop Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "center" }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                href={page.link}
                sx={{
                  color: "#000",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textTransform: "none",
                  mx: 2,
                  position: "relative",
                  "&:hover": { color: "#0070f3" },
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          {/* Contattaci Button */}
          <Button
            href="/Contattaci"
            sx={{
              backgroundColor: "#0070f3",
              color: "#fff",
              textTransform: "none",
              fontWeight: "bold",
              px: 3,
              py: 1,
              borderRadius: "20px",
              boxShadow: "0px 4px 6px rgba(0, 112, 243, 0.3)",
              "&:hover": {
                backgroundColor: "#0056b3",
                boxShadow: "0px 6px 8px rgba(0, 112, 243, 0.5)",
              },
            }}
          >
            Contattaci
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
