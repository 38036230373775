import { Box, Typography, Avatar } from "@mui/material";
import React from "react";
import image from "../assets/images/samsung.jpg";
import { HelmetProvider, Helmet } from "react-helmet-async";
import fotoProfilo from "../assets/images/fotoProfilo.jpg";
const AboutUs = () => {
  return (
    <>
      {/* SEO: Titolo e meta description */}

      <HelmetProvider>
        <Helmet>
          <title>Chi Siamo - La nostra missione e il nostro team</title>
          <meta
            name="description"
            content="Scopri chi siamo: un team giovane e appassionato che sviluppa soluzioni web moderne per far crescere il tuo business online."
          />
        </Helmet>

        <Box
          sx={{
            padding: { xs: "2rem", md: "4rem" },
            backgroundColor: "#F9F9F9",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2rem",
            textAlign: "center",
          }}
        >
          {/* Titolo principale: h1 per SEO */}
          <Typography
            variant="h1"
            fontWeight="bold"
            sx={{
              color: "#333",
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            Chi siamo
          </Typography>

          {/* Introduzione */}
          <Typography
            variant="body1"
            sx={{
              maxWidth: "800px",
              color: "#555",
              fontSize: { xs: "1rem", md: "1.2rem" },
              lineHeight: 1.6,
            }}
          >
            Siamo un team giovane e appassionato specializzato nello sviluppo di
            applicazioni web. Con un focus su tecnologie moderne come React,
            Angular e Java, trasformiamo le tue idee in esperienze digitali
            uniche.
          </Typography>

          {/* Immagine con lazy loading per performance */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <img
              src={image}
              alt="Il nostro team"
              loading="lazy" // Lazy load per performance
              style={{
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Box>

          {/* Descrizione dettagliata */}
          <Box
            sx={{
              maxWidth: "800px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              fontWeight="bold"
              sx={{
                color: "#333",
                marginBottom: "1rem",
              }}
            >
              La nostra missione
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#555",
                fontSize: { xs: "1rem", md: "1.2rem" },
                lineHeight: 1.6,
              }}
            >
              Crediamo nel potere del digitale per cambiare il mondo. La nostra
              missione è creare soluzioni web innovative e accessibili, aiutando
              aziende e privati a crescere e a raggiungere i loro obiettivi
              online. Collaboriamo con i nostri clienti per garantire risultati
              che superino le aspettative.
            </Typography>
          </Box>

          {/* Avatar per personalizzazione */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <Avatar
              alt="Fondatore"
              src={fotoProfilo} // Cambia con una tua immagine
              sx={{
                width: 150,
                height: 150,
                marginBottom: "1rem",
              }}
            />
            <Typography variant="h6" fontWeight="bold" color="#333">
              Kavidu Francesco
            </Typography>
            <Typography variant="body2" color="#555">
              Sviluppatore Full Stack e fondatore
            </Typography>
          </Box>
        </Box>
      </HelmetProvider>
    </>
  );
};

export default AboutUs;
